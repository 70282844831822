import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'JsonLogicFormatString',
})
export class JsonLogicFormatStringPipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    if (!value) {
      return '';
    }
    console.log(this.translateRule(value));
    return this.translateRule(value);
  }

  translateRule(rule: any): string {
    if (typeof rule !== 'object' || rule === null) return '';

    const keys = Object.keys(rule);
    if (keys.length !== 1) return '';

    const operator = keys[0];
    const conditions = rule[operator];

    if (!Array.isArray(conditions)) return '';

    if (operator === 'and' || operator === 'or') {
      return conditions.map(rule => this.translateRule(rule)).filter(Boolean).join(` ${OPERATOR_MAP[operator]} `);
    }

    const parseCondition = (condition: any): string => {
      if (typeof condition === 'object' && condition?.var) {
        return `${condition.var}`;
      }
      return JSON.stringify(condition);
    };

    if (conditions.length === 2) {
      const left = parseCondition(conditions[0]);
      const right = parseCondition(conditions[1]);
      return `${left} ${OPERATOR_MAP[operator] || operator} ${right}`;
    }

    return '';
  }
}

const OPERATOR_MAP: Record<string, string> = {
  '===': 'é igual a',
  '!==': 'é diferente de',
  '<': 'é menor que',
  '<=': 'é menor ou igual a',
  '>': 'é maior que',
  '>=': 'é maior ou igual a',
  'some': 'contém',
  'in': 'contém',
  'and': 'e',
  'or': 'ou'
};
