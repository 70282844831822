import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
    dayjs.extend(duration);
    dayjs.locale('pt-br');
    dayjs.extend(customParseFormat);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isToday);
    dayjs.extend(LocalizedFormat);
  }

  sortByDateDescending(objArray: any[], paramName: string): any[] {
    const objectsWithDate: any[] = [];
    const objectsWithoutDate: any[] = [];

    for (const obj of objArray) {
      if (!!obj[paramName] && obj[paramName].trim() === '') {
        objectsWithoutDate.push(obj);
      } else {
        objectsWithDate.push(obj);
      }
    }

    objectsWithDate.sort((a, b) => {
      const dateA = new Date(a[paramName]);
      const dateB = new Date(b[paramName]);
      return dateB.getTime() - dateA.getTime();
    });

    return [...objectsWithDate, ...objectsWithoutDate];
  }


  public isDateValid(date: string, format = 'YYYY-MM-DD'): boolean {
    return dayjs(date, format).isValid();
  }

  public getTime(date: string, timeZoneId = 'america/sao_paulo'): number {
    return dayjs.tz(dayjs(date), timeZoneId).unix();
  }

  public manipulateDate(qtd, startOf?, date?) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    if (startOf) {
      if (date) {
        return dayjs(date, 'YYYY-MM-DD')
          .startOf('day')
          .add(qtd, 'day')
          .format(format);
      }
      return dayjs().startOf('day').add(qtd, 'day').format(format);
    }
    if (date) {
      return dayjs(date, 'YYYY-MM-DD')
        .endOf('day')
        .add(qtd, 'day')
        .format(format);
    }
    return dayjs().endOf('day').add(qtd, 'day').format(format);
  }

  format(date: string, format?: DateTime, toDb?: boolean, formatDb: DateTime = 'date') {
    if (toDb) {
      const dayjsInstance: any = dayjs(date, OUTPUT_FORMAT[format]);
      if (formatDb === 'dateTimeUtc') {
        return dayjsInstance.utc().format(OUTPUT_FORMAT[formatDb]);
      }
      return dayjsInstance.format(OUTPUT_FORMAT[formatDb]);
    }
    return dayjs(date).format(OUTPUT_FORMAT[format]);
  }

  formatPipe(date, format, toDb = false) {
    if (toDb) {
      if (!format) {
        format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      }
      return dayjs(date, 'DD/MM/YYYY').format(format);
    }
    return dayjs(date).format(format);
  }

  getLocalDateTime(format) {
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSS';
    }
    return dayjs().format(format);
  }

  getUtcDateTime(format?) {
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }
    return dayjs.utc().format(format);
  }

  setUtcDateTime(date, time, format?) {
    time = time.replace(/:/g, '');
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }

    date.setHours(time.substring(0, 2));
    date.setMinutes(time.substring(2, 4));
    date.setSeconds(time.substring(4, 6));
    return dayjs(date).utc().format(format);
  }

  getDateFormatted(date, format, formatExpected) {
    if (date) {
      return dayjs(date, format).format(formatExpected);
    }
    return '';
  }

  getDayOfWeek(day) {
    return dayjs().day(day);
  }

  formatDateTimeToLocal(date, format, timeZoneId = 'America/Sao_paulo') {
    if (!!OUTPUT_FORMAT[format]) {
      format = OUTPUT_FORMAT[format];
    }
    return dayjs.tz(dayjs(date), timeZoneId).format(format);
  }

  lifeTime(date, timeZoneId) {
    return dayjs(
      this.formatDateTimeToLocal(date, 'YYYY-MM-DDTHH:mm:ss.SSS', timeZoneId),
    )
      .startOf('s')
      .fromNow(true);
  }

  diffByYears(date) {
    return dayjs().diff(date, 'years');
  }

  diff(date, unitOfTime) {
    return dayjs().diff(date, unitOfTime);
  }
}

const OUTPUT_FORMAT = {
  dateLocal: 'DD/MM/YYYY',
  dateTimeLocal: 'DD/MM/YYYY HH:mm:ss',
  date: 'YYYY-MM-DD',
  time: 'HH:mm:ss',
  dateTime: 'YYYY-MM-DDTHH:mm:ss',
  dateTimeUtc: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
};
type DateTime = 'date' | 'dateLocal' | 'dateTimeLocal' | 'time' | 'dateTime' | 'dateTimeUtc';
