import { Injectable } from '@angular/core';
import { FormAnswer, FormQuestion } from '../models/form.model';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class FormQuestionEntityService extends BaseEntityService<FormQuestion> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
    basePermissionsService: BasePermissionsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'formQuestion', plural: 'formQuestions', url: 'form-questions' },
      routerParamsService,
      basePermissionsService.formQuestion,
    );
    this.nameId = 'formQuestionId';
  }
}
