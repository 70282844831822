import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportsService } from './reports.service';

export interface ILayoutConf {
  toolbarNav?: boolean;
  menu?: Menu[];
  menuFooter?: Menu[];
  updatedAt?: number;
  menuBanned?: string[];
  titlePage?: string;
}


@Injectable({
  providedIn: 'root'
})
export class MenuLayoutService {
  public layoutConf: ILayoutConf = {
    toolbarNav: true,
  };
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  menu: Menu[] = [];
  menuFooter: Menu[] = [];

  constructor(
    private reportsService: ReportsService,
  ) {
    this.setMenu();
    this.setMenuFooter();
  }

  updateLayoutConfig() {
    this.setLayout({ updatedAt: new Date().getTime() });
  }

  setLayout(layoutConfSet: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConfSet };
    this.layoutConfSubject.next(this.layoutConf);
  }

  setMenuFooter() {
    this.menuFooter = [
      {
        type: 'divider'
      },
      {
        icon: 'logout',
        label: 'Sair',
        shortLabel: 'Sair',
        type: 'item',
        path: '/sessions/logout',
      },
    ];
    this.setLayout({ menuFooter: this.menuFooter });
  }

  setMenu() {
    this.menu = [

      {
        icon: 'business',
        label: 'Clientes',
        shortLabel: 'Clientes',
        type: 'item',
        showPath: false,
        path: '/organizacoes',
        activeType: 'FULL'
      },
      ...this.menuOrganizations(),
      {
        icon: 'person',
        label: 'Pacientes',
        shortLabel: 'Pacientes',
        type: 'item',
        showPath: false,
        path: '/pessoas',
        activeType: 'FULL',
        excludeByParam: 'organizationId',
      },
      ...this.menuPersons(),
      {
        icon: 'notes',
        label: 'Fila de exames',
        shortLabel: 'Fila exames',
        type: 'item',
        showPath: false,
        path: '/pessoas/exames-pendentes',
        activeType: 'PARTIAL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'warning',
        label: 'BirdId',
        shortLabel: 'BirdId',
        type: 'item',
        showPath: false,
        path: '/organizacoes/birdid',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'notifications',
        label: 'Notificações',
        shortLabel: 'Notificações',
        type: 'item',
        showPath: false,
        path: '/organizacoes/notificacoes',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'summarize',
        label: 'Report Template',
        shortLabel: 'Report Template',
        type: 'item',
        showPath: false,
        path: '/organizacoes/report-template',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'toggle_on',
        label: 'Feature Flags',
        shortLabel: 'Feature Flags',
        type: 'item',
        showPath: false,
        path: '/organizacoes/feature-flags',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'forum',
        label: 'Conversa com assistente médica',
        shortLabel: 'Assistente médica',
        type: 'item',
        showPath: false,
        path: '/pessoas/conversas-com-assistente-medica',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
      {
        icon: 'format_list_bulleted',
        label: 'Formulários',
        shortLabel: 'Formulários',
        type: 'item',
        showPath: false,
        path: '/pessoas/formularios',
        activeType: 'FULL',
        excludeByParam: 'organizationId,personId',
      },
    ];
    this.setLayout({ menu: this.menu });
  }

  private menuPersons(): Menu[] {
    return [
      {
        icon: 'calculate',
        label: 'Riscos',
        shortLabel: 'Riscos',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/riscos',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'monitor_heart',
        label: 'Exames',
        shortLabel: 'Exames',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/exames',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'forward',
        label: 'Encaminhamentos',
        shortLabel: 'Encaminhamentos',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/encaminhamentos',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'inventory',
        label: 'Questionários',
        shortLabel: 'Questionários',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/questionario',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'history',
        label: 'Histórico',
        shortLabel: 'Histórico',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/historico',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      {
        icon: 'forum',
        label: 'Conversa com assistente médica',
        shortLabel: 'Assistente médica',
        type: 'item',
        showPath: false,
        idRequired: 'personId',
        path: '/pessoas/personId/conversas-com-assistente-medica',
        excludeByParam: 'organizationId',
        activeType: 'FULL'
      },
      // {
      //   name: 'Exames',
      //   type: 'link',
      //   tooltip: 'Exames',
      //   icon: 'monitor_heart',
      //   state: this.urlService.personDetails(personId),
      //   disabled: !(
      //     this.aclService.hasPermissionEntity('performedExam', 'read') ||
      //     this.aclService.hasPermissionEntity('examRequest', 'read') ||
      //     this.aclService.hasPermissionEntity('examParse', 'read')
      //   ),
      // {
      //   name: 'Riscos',
      //   type: 'link',
      //   tooltip: 'Riscos',
      //   icon: 'calculate',
      //   state: this.urlService.personDetailsRisk(personId),
      //   disabled: !this.aclService.hasPermissionEntity('selfieConsolidated', 'read'),
      // },
    ]
  }

  private menuOrganizations(): Menu[] {
    return [
      {
        icon: 'corporate_fare',
        label: 'Cliente detalhes',
        shortLabel: 'Cliente detalhes',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId',
        excludeByParam: 'personId',
        activeType: 'FULL'
      },
      {
        icon: 'chat',
        label: 'Regras de notificações customizadas',
        shortLabel: 'Regras Notica...',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/regras-notificacao-customizada',
        excludeByParam: 'personId',
        activeType: 'FULL'
      },
      {
        icon: 'event_available',
        label: 'Ciclos',
        shortLabel: 'Ciclos',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/ciclos',
        activeType: 'FULL',
        excludeByParam: 'personId',
        acl: ['HUMAN_RESOURCES']
      },
      {
        icon: 'free_cancellation',
        label: 'Gratuidade de exames',
        shortLabel: 'Gratuidade',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/gratuidade-exames',
        excludeByParam: 'personId',
        activeType: 'FULL',
      },
      {
        icon: 'settings_suggest',
        label: 'Regra de Recomendações',
        shortLabel: 'Regra de Recomenda',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/recomendacoes',
        excludeByParam: 'personId',
        activeType: 'FULL',
      },
      {
        icon: 'rule_folder',
        label: 'Permissões',
        shortLabel: 'Permissões',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/permissoes',
        excludeByParam: 'personId',
        activeType: 'FULL',
      },
      {
        icon: 'import_export',
        label: 'Import / Export',
        shortLabel: 'Import  Export',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/import-export',
        activeType: 'FULL',
        excludeByParam: 'personId',
        checkParent: true,
      },
      {
        icon: 'table_rows',
        label: 'Navegações Airtable',
        shortLabel: 'Navegações Airtable',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/navegacao-airtable',
        excludeByParam: 'personId',
        activeType: 'FULL',
      },
      {
        icon: 'article',
        label: 'Termos',
        shortLabel: 'Termos',
        type: 'item',
        showPath: false,
        idRequired: 'organizationId',
        path: '/organizacoes/organizationId/termos',
        excludeByParam: 'personId',
        activeType: 'FULL',
      },
    ];
  }
}


export interface Menu {
  path?: string;
  showPath?: boolean;
  idRequired?: string;
  excludeByParam?: string;
  checkParent?: boolean;
  acl?: acl[],
  icon?: string;
  label?: string;
  shortLabel?: string;
  active?: boolean;
  activeType?: 'PARTIAL' | 'FULL'
  type: 'item' | 'submenu' | 'divider';
}

export type acl = 'HUMAN_RESOURCES' | 'BROKER' | 'HEALTH_OPERATOR';
