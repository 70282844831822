import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { filter, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  template: `
    <mat-form-field class="w-100">
      <mat-label>{{ props?.label }}</mat-label>
      <input type="text"
             #input
             [placeholder]="props?.placeholder ?? 'Digite para buscar'"
             aria-label="Digite para buscar"
             matInput
             (focusout)="filter()"
             [formControl]="formControl"
             [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption
                        #auto="matAutocomplete" [displayWith]="displayFn">
        @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">{{ option.label }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: [`
    .chip-list {
      width: 100%;
    }
  `]
})
export class FormlyFieldAutocompleteTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;
  filteredOptions: Observable<any[]>;
  oneTimeRequest = false;

  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      filter(response => typeof response === 'string'),
      map(value => this._filter(value || '')),
    );
  }

  setValue(): void {
    if (this.oneTimeRequest) {
      setTimeout(() => {
        this.oneTimeRequest = false;
        const options = this._auto.autocomplete.options.toArray();
        this._auto.writeValue(options[0].value);
      }, 0);
    }
  }

  findOptionByValue(value: string): { value: any, label: string } {
    const options: any[] = this.props?.options as any[];
    return options.find(o => o.value === value);
  }

  get userOptions(): any[] {
    return this.props?.options || [] as any;
  }

  private _filter(value: string): string[] {
    return this.userOptions.filter(option => this.removeAccents(option?.label)?.includes(this.removeAccents(value)));
  }

  private removeAccents(str: string): string {
    if (!str || str === '') {
      return str;
    }
    return str
      .normalize('NFD') // Separa os caracteres acentuados em partes base + acento
      .replace(/[\u0300-\u036f]/g, '') // Remove os diacríticos (acentos)
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/[\r\n]/g, '')
      .trim()
      .toLowerCase()
      ;
  }

  filter(): void {
    const filterValue = this.removeAccents(this.input.nativeElement.value);
    console.log(filterValue);
    const optionFound = this.userOptions.some(option => {
      console.log(this.removeAccents(option.label), ' # ', filterValue);
      return this.removeAccents(option.label) === filterValue;
    });
    if (!optionFound) {
      this.formControl.setValue(undefined);
    }
  }

  displayFn(response: any): string {
    if (!response?.label) {
      return '';
    }
    return `${ response?.label }`;
  }
}
