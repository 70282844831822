import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatInputModule } from '@ngx-formly/material/input';
import { FormlyMatRadioModule } from '@ngx-formly/material/radio';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { addonsExtension } from './addons/addons.extension';
import { FormlyWrapperAddons } from './addons/addons.wrapper';
import {
  FormlyFieldCurrencyTypeComponent
} from './components/formly-field-currency-type/formly-field-currency-type.component';
import {
  FormlyFieldMaskedTypeComponent
} from './components/formly-field-masked-type/formly-field-masked-type.component';
import { GenericValidator } from './services/generic.validator';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { SharedMaterialModule } from '../shared/shared-material.module';
import { ValidatorCpf } from './validators/validator-cpf';
import {
  FormlyFieldToggleTypeComponent
} from './components/formly-field-toggle-type/formly-field-toggle-type.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ValidatorEmail } from './validators/validator-email';
import { IMaskModule } from 'angular-imask';
import { SharedDirectivesModule } from '../shared/directives/shared.directives.module';
import { FlexLayoutType } from './components/formly-flex-layout';
import { FormlyFieldCodeTypeComponent } from './components/formly-field-code-type/formly-field-code-type.component';
import { FormlyChipsComponent } from './components/formly-field-chips-type/formly-field-chips-type.component';
import {
  FormlyFieldAutocompleteTypeComponent
} from './components/formly-field-autocomplete-type/formly-field-autocomplete-type.component';
import { FormlyButtonIconComponent } from './components/formly-button-icon/formly-button-icon.component';

@NgModule({
  declarations: [
    FormlyButtonIconComponent,
    FormlyFieldAutocompleteTypeComponent,
    FormlyChipsComponent,
    FormlyFieldCodeTypeComponent,
    FlexLayoutType,
    FormlyWrapperAddons,
    FormlyFieldToggleTypeComponent,
    FormlyFieldCurrencyTypeComponent,
    FormlyFieldMaskedTypeComponent,
  ],
  imports: [
    IMaskModule,
    SharedMaterialModule,
    NgxFileDropModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    FormsModule,
    CommonModule,
    MatButtonToggleModule,
    FormlyMatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyMatInputModule,
    FormlyMaterialModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      wrappers: [{ name: 'addons', component: FormlyWrapperAddons }],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
      ],
      validators: [
        {
          name: 'fieldMatch',
          validation: GenericValidator.fieldMatchValidator,
        },
        { name: 'email', validation: ValidatorEmail },
        { name: 'cpf', validation: ValidatorCpf },
        { name: 'cnpj', validation: GenericValidator.isValidCnpj },
      ],
      validationMessages: [
        { name: 'required', message: 'Este campo é obrigatório' },
        {
          name: 'minlength',
          message: GenericValidator.formlyMinLengthValidationMessage,
        },
        {
          name: 'cpf',
          message: 'CPF inválido',
        },
        {
          name: 'email',
          message: 'E-mail inválido',
        }
      ],
      types: [
        { name: 'autocomplete', component: FormlyFieldAutocompleteTypeComponent },
        { name: 'buttonIcon', component: FormlyButtonIconComponent },
        { name: 'chips', component: FormlyChipsComponent },
        { name: 'flex-layout', component: FlexLayoutType },
        { name: 'code', component: FormlyFieldCodeTypeComponent },
        {
          name: 'radio',
          defaultOptions: {
            props: {
              appearance: 'fill',
            },
          },
        },
        {
          name: 'masked',
          component: FormlyFieldMaskedTypeComponent,
          defaultOptions: {
            props: {
              type: 'phone',
            },
          },
        },
        {
          name: 'currency',
          component: FormlyFieldCurrencyTypeComponent,
          defaultOptions: {
            props: {},
          },
        },
        {
          name: 'buttonToggle',
          component: FormlyFieldToggleTypeComponent,
          defaultOptions: {
            props: {},
          },
        },
      ],
    }),
    MatIconModule,
    SharedDirectivesModule,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
  exports: [
    FormlyFieldAutocompleteTypeComponent,
    FormlyMatDatepickerModule,
    FormlyMatInputModule,
    FormlyMaterialModule,
    FormlyFieldCurrencyTypeComponent,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class FormModule {
}
