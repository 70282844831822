import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-button-icon',
  template: `
    <button style="margin-top: 4px" type="button"
            [matTooltip]="props?.tooltip || undefined"
            [matTooltipDisabled]="!props?.tooltip"
            mat-icon-button
            (click)="props.onClick(props?.clickReturn)">
      <mat-icon>{{ props.icon }}</mat-icon>
    </button>
  `
})
export class FormlyButtonIconComponent extends FieldType {}
